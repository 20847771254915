import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../provider/languageContext";
import moment from "moment";
import storage from "../../services/storage";
import { API_URL } from "../../utilities/url";
import { format_number } from "../../utilities/ext-function";
import { CartContext } from "../../provider/cartContext";
import axios from "axios";
var $ = require("jquery");

const Notification = () => {
  const { messagesLang } = useLanguage();
  const navigate = useNavigate();
  const { countOrderProcess } = useContext(CartContext);
  const [openRecent, setOpenRecent] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const tableRef = useRef(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.value === "") {
      setActiveSearch(false);
    }
    if (!activeSearch) {
      setSearchResult(e.target.value);
    }
  };
  const clearInput = () => {
    setActiveSearch(false);
    setInputValue("");
  };
  const handleSearch = () => {};
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleTab = (data) => {
    if (activeTab !== data) {
      setActiveTab(data);
    }
  };

  const markAsRead = () => {
    axios
      .post(API_URL + "update-notification", null, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          initializeDataTable();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    const newTable = $("#example").DataTable({
      dom:
        "<'row'<'col-sm-6'l><'col-sm-6'Bf>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: "true",
      language: {
        processing: `<div class="spinner-border spinner-border-sm text-primary" role="status">
          <span class="visually-hidden"></span>
          </div>`,
      },
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-notifications",
        type: "post",
        headers: {
          Authorization: "Bearer " + storage.get("access_token"),
        },
        data: function (d) {
          return {
            draw: d.draw,
            start: d.start,
            length: d.length,
            search: d.search.value,
            status: activeTab,
            orderBy: d.columns[d.order[0].column].data,
            orderDir: d.order[0].dir,
          };
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            for (let i = 0; i < localStorage.length; i++) {
              const key = localStorage.key(i);
              if (key !== "lang") {
                storage.remove("access_token");
                localStorage.removeItem(key);
              }
            }
            window.location.replace("login");
          }
        },
      },
      columns: [
        {
          data: "ts_created",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY HH:mm:ss");
            return data;
          },
        },
        { data: "title" },
        { data: "description" },
        { data: "status_label" },
      ],
      order: [[0, "desc"]],
      createdRow: function (row, data) {
        if (data.status_label === "Read") {
          $(row);
        } else {
          $(row)
            .addClass("cursor-pointer")
            .on("click", async function () {
              try {
                const apiUrl = `${API_URL}update-notification`;
                const response = await axios.post(
                  apiUrl,
                  { idt_notification: data.idt_notification },
                  {
                    headers: {
                      Authorization: `Bearer ${storage.get("access_token")}`,
                    },
                  }
                );

                if (response.data.success === true) {
                  newTable.ajax.reload(null, false);
                }
              } catch (error) {
                console.error("Error fetching notification details:", error);
              }
            });
        }
        $(row).find("td").eq(5).addClass("text-center");
      },
      initComplete: function (settings, json) {
        const searchInput = $("div.dataTables_filter input");
        searchInput.off(); // Remove previous event handlers
        searchInput.on("keypress", function (e) {
          if (e.which === 13) {
            // Check if Enter key is pressed
            newTable.search(this.value).draw();
          }
        });
        // Handle click event on anchor tags
        $("#example").on("click", "a", function (e) {
          e.preventDefault();
          const idOrder = $(this).data("id");
          navigate(`/order-detail/${idOrder}`);
          storage.setUnlimited("orderId", idOrder);
        });
      },
    });

    return newTable;
  };

  useEffect(() => {
    initializeDataTable();
  }, [activeTab]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="card shadow-lg" id="orderList">
              <div className="card-header border-0">
                <div className="row align-items-center gy-3">
                  <div className="col-sm">
                    <div className="d-flex justify-content-between">
                      <h5 className="card-title mb-0">
                        {messagesLang.notifications}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body border border-dashed border-end-0 border-start-0">
                {/* <div className="col-12 col-md-3 col-sm-6">
                  <div className="app-search" style={{ paddingLeft: "0" }}>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={messagesLang.search + "..."}
                        autoComplete="off"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                      />
                      <span
                        onClick={handleSearch}
                        className="mdi mdi-magnify search-widget-icon cursor-pointer"
                      ></span>
                      {inputValue && (
                        <span
                          onClick={clearInput}
                          className="mdi mdi-close-circle search-widget-icon search-widget-icon-close cursor-pointer"
                          id="search-close-options"
                        ></span>
                      )}
                    </div>
                  </div>
                </div> */}
                <ul
                  className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className={
                        activeTab === "all"
                          ? "nav-link active cursor-pointer py-3"
                          : "nav-link cursor-pointer py-3"
                      }
                      onClick={() => handleTab("all")}
                    >
                      <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                      {messagesLang.all}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        activeTab === "read"
                          ? "nav-link active cursor-pointer py-3"
                          : "nav-link cursor-pointer py-3"
                      }
                      onClick={() => handleTab("read")}
                    >
                      <i className="ri-mail-open-line me-1 align-bottom"></i>{" "}
                      {messagesLang.read}{" "}
                      {countOrderProcess > 0 && (
                        <span className="badge bg-primary align-middle ms-1">
                          {countOrderProcess}
                        </span>
                      )}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={
                        activeTab === "unread"
                          ? "nav-link active cursor-pointer py-3"
                          : "nav-link cursor-pointer py-3"
                      }
                      onClick={() => handleTab("unread")}
                    >
                      <i className="ri-mail-unread-line me-1 align-bottom"></i>{" "}
                      {messagesLang.unread}
                    </a>
                  </li>
                </ul>
                <button
                  onClick={() => markAsRead()}
                  class="btn btn-success btn-sm mb-3"
                >
                  <i class="ri-check-double-line me-1"></i> Mark All as Read
                </button>

                <div className="table-responsive mb-1">
                  <table
                    id="example"
                    ref={tableRef}
                    className="table table-hover table-bordered w-100"
                  >
                    <thead className="table-light">
                      <tr>
                        <th>DATE</th>
                        <th>TITLE</th>
                        <th>DESCRIPTION</th>
                        <th>STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <div ref={loaderRef} className="loader-dt">
                      Loading...
                    </div> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
