import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import { SliderRecentContext } from "../../../provider/sliderRecentContext";
import { CardSkeleton } from "../../../components";
import bookComp from "../../../assets/images/book-comp.png";
import image_knowledge from "../../../assets/images/knowledge.png";
import Skeleton from "react-loading-skeleton";
import { LuBookCopy, LuBookLock, LuBookUp } from "react-icons/lu";
import { BiWorld } from "react-icons/bi";
import { format_number } from "../../../utilities/ext-function";
import { SessionContext } from "../../../provider/sessionContext";
import { AssistantContext } from "../../../provider/assistantContext";
import { ToastrContext } from "../../../provider/toastrContext";
import CryptoJS from "crypto-js";
import axios from "axios";
import { API_URL, API_URL_CORE } from "../../../utilities/url";
import storage from "../../../services/storage";

const NextArrow = (props) => {
  const { className, style, onClick, currentSlide, slideCount, slidesToShow } =
    props;
  if (currentSlide + slidesToShow >= slideCount) {
    return null;
  }
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick, currentSlide } = props;
  if (currentSlide === 0) {
    return null;
  }
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
};

const getSlidesConfig = (width) => {
  if (width > 3000) {
    return 10;
  } else if (width <= 3000 && width > 2500) {
    return 8;
  } else if (width <= 2500 && width > 2000) {
    return 7;
  } else if (width <= 2000 && width > 1500) {
    return 6;
  } else if (width <= 1500 && width > 1024) {
    return 5;
  } else if (width <= 1024 && width > 600) {
    return 4;
  } else if (width <= 600) {
    return 2;
  } else {
    return 10;
  }
};

const CarouselRecent = () => {
  const {
    sliderData,
    currentSlide,
    setCurrentSlide,
    setCurrentPage,
    loading,
    loadData,
    firstLoad,
    setFirstLoad,
    addMoreData,
  } = useContext(SliderRecentContext);
  const {
    setMessages,
    setSessionList,
    setSelectedSession,
    setActiveSession,
    setActiveMessage,
    setTypeSession,
    loadSessionPersonal,
  } = useContext(SessionContext);
  const { defaultAssistant, setSelectedSessionAssistant } =
    useContext(AssistantContext);
  const { showError } = useContext(ToastrContext);
  const [slidesConfig, setSlidesConfig] = useState(
    getSlidesConfig(window.innerWidth)
  );
  const navigate = useNavigate();
  const [hasLoaded, setHasLoaded] = useState(false);
  const sliderRef = useRef(null);
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);

  useEffect(() => {
    // if (window.innerWidth < 1024) {
    //   setTimeout(() => {
    //     if (sliderRef.current) {
    //       sliderRef.current.slickGoTo(0, true);
    //     }
    //   }, 1500);
    // }
    const handleResize = () => {
      setSlidesConfig(getSlidesConfig(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!hasLoaded && firstLoad) {
      loadData();
      setHasLoaded(true);
      setFirstLoad(false);
    }
  }, [hasLoaded, sliderData, loadData]);

  useEffect(() => {
    if (sliderRef.current) {
      const track = sliderRef.current.querySelector(
        ".know-recent .slick-track"
      );
      if (track) {
        if (sliderData.length < slidesConfig) {
          track.classList.add("slick-lock");
        } else {
          track.classList.remove("slick-lock");
        }
      }
    }
  }, [sliderData, slidesConfig]);

  const settings = {
    // dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: slidesConfig,
    slidesToScroll: slidesConfig,
    initialSlide: currentSlide,
    afterChange: (current) => {
      setCurrentSlide(current);
      if (current === sliderData.length - 1 && sliderData.length % 10 === 0) {
        setCurrentPage((prevPage) => prevPage + 1);
        addMoreData();
      }
    },
    nextArrow: <NextArrow slidesToShow={slidesConfig} />,
    prevArrow: <PrevArrow slidesToShow={slidesConfig} />,
  };

  const startChat = (id, option) => {
    axios
      .post(API_URL_CORE + "chat/session", {
        knowledge_id: option === "knowledge" ? id : null,
        collection_id: option === "collection" ? id : null,
        assistant_id: defaultAssistant,
        user_id: usr.idt_user,
      })
      .then((response) => {
        if (response.data.errormsg === "OK") {
          // Get For Context
          axios
            .post(
              API_URL + "get-session",
              { status: 0, idt_session: response.data.data.idt_session },
              {
                headers: {
                  Authorization: `Bearer ${storage.get("access_token")}`,
                },
              }
            )
            .then((resp) => {
              if (resp.data.success === true) {
                setSessionList([]);
                loadSessionPersonal();
                setTypeSession("personal");
                setMessages([]);
                setSelectedSessionAssistant({
                  value: resp.data.data[0].assistant.idt_user_assistant,
                  label: resp.data.data[0].assistant.name,
                });
                setSelectedSession(resp.data.data[0]);
                setActiveMessage(true);
                setActiveSession(resp.data.data[0].idt_session);
                navigate("/chat");
              }
            })
            .catch((error) => {
              showError(error);
            });
        } else {
          showError(response.data.errormsg);
        }
      })
      .catch((error) => {
        showError(error);
      });
  };

  const getPrice = (card) => {
    if (card.idt_knowledge !== undefined) {
      if (card.knowledge_public.length > 0) {
        if (card.knowledge_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (card.knowledge_public[0].pricing_scheme === "rent") {
          return "Rp. " + format_number(card.knowledge_public[0].price_rent);
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    } else {
      return `${card.knowledge_collection_map.length} Knowledge`;
    }
  };

  return sliderData.length > 0 ? (
    <div className="container-fluid w-85">
      <div className="row">
        <div className="d-flex active collapsed">
          <div className="file-list-link fs-14">Recent Knowledge</div>
          <div className="line-with-text2"></div>
        </div>
        <div className="w-100 know-recent" ref={sliderRef}>
          <Slider {...settings}>
            {loading
              ? sliderData.map((index) => <CardSkeleton key={index} />)
              : sliderData.map((card, index) => (
                  <div key={index} className="p-25">
                    <div
                      className="card card-list shadow-lg cursor-pointer"
                      onClick={() => startChat(card.idt_knowledge, "knowledge")}
                    >
                      <div className="card-body">
                        <div className="mb-2">
                          <div className="square-container">
                            <img
                              src={
                                card.idt_knowledge !== undefined
                                  ? card.knowledge_cover_pic.length > 0
                                    ? card.knowledge_cover_pic[0].pathurl
                                    : image_knowledge
                                  : bookComp
                              }
                              className="rounded-3"
                              alt="knowledge"
                            />
                          </div>
                        </div>
                        <div style={{ height: "36px" }}>
                          <h6 className="fs-12 fw-bold ellipsis-2-line knowledge-name">
                            {card.title !== undefined ? card.title : card.name}
                          </h6>
                        </div>
                        <p className="text-start fs-11 knowledge-name mb-0">
                          {card.type !== undefined ? (
                            <>
                              <BiWorld
                                size={11}
                                className="me-1"
                                style={{ marginTop: "-3px" }}
                              />
                              Public
                            </>
                          ) : (
                            <>
                              <LuBookCopy
                                size={11}
                                className="me-1"
                                style={{ marginTop: "-3px" }}
                              />
                              Collection
                            </>
                          )}
                        </p>
                        <p className="text-start fw-bold fs-12 knowledge-name mb-0">
                          {getPrice(card)}
                        </p>
                        <p className="text-start fs-12 knowledge-name mb-0">
                          {card.author !== undefined
                            ? card.author
                              ? card.author
                              : "-"
                            : "Multiple Publisher"}
                        </p>
                        <p className="text-start fs-12 knowledge-name mb-0">
                          {card.knowledge_publisher !== undefined
                            ? card.knowledge_publisher
                              ? card.knowledge_publisher.name
                              : "-"
                            : null}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
          </Slider>
        </div>
      </div>
    </div>
  ) : loading ? (
    <div className="container-fluid w-85">
      <div className="row">
        <Skeleton className="custom-skeleton hcl-skeleton mb-3" />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CarouselRecent;
