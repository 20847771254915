import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SliderProcessingContext } from "../../../provider/sliderProcessingContext";
import { CardSkeleton, ModalRemove } from "../../../components";
import ProgressBar from "../../../components/progressBar";
import { useLanguage } from "../../../provider/languageContext";
import { LuBookCopy, LuBookLock, LuBookUp } from "react-icons/lu";
import { Dropdown } from "react-bootstrap";
import { HiOutlineDocumentArrowUp } from "react-icons/hi2";
import { ToastrContext } from "../../../provider/toastrContext";
import axios from "axios";
import { API_URL } from "../../../utilities/url";
import storage from "../../../services/storage";
import { WebSocketContext } from "../../../provider/webSocketContext";

const NextArrow = (props) => {
  const { className, style, onClick, currentSlide, slideCount, slidesToShow } =
    props;
  if (currentSlide + slidesToShow >= slideCount) {
    return null;
  }
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick, currentSlide } = props;
  if (currentSlide === 0) {
    return null;
  }
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
};

const getSlidesConfig = (width) => {
  if (width > 3000) {
    return 10;
  } else if (width <= 3000 && width > 2500) {
    return 8;
  } else if (width <= 2500 && width > 2000) {
    return 7;
  } else if (width <= 2000 && width > 1500) {
    return 6;
  } else if (width <= 1500 && width > 1024) {
    return 5;
  } else if (width <= 1024 && width > 600) {
    return 4;
  } else if (width <= 600) {
    return 2;
  } else {
    return 10;
  }
};

const CarouselProcessing = () => {
  const {
    sliderData,
    setSliderData,
    currentSlide,
    setCurrentSlide,
    setCurrentPage,
    loading,
    loadData,
    loadDataNoLoading,
    firstLoad,
    setFirstLoad,
    addMoreData,
  } = useContext(SliderProcessingContext);
  const { ws, dataSocket } = useContext(WebSocketContext);
  const { messagesLang } = useLanguage();
  const { showSuccess, showError } = useContext(ToastrContext);
  const [slidesConfig, setSlidesConfig] = useState(
    getSlidesConfig(window.innerWidth)
  );
  const sliderRef = useRef(null);

  useEffect(() => {
    if (ws && dataSocket) {
      const data = dataSocket;
      if (data.stage === "TrainKnowledge" && data.process > 0) {
        const updatedData = sliderData.map((item) => {
          if (item.idt_knowledge === data.knowledge_id) {
            return {
              ...item,
              knowledge_processing_job: item.knowledge_processing_job.map(
                (job) => {
                  if (
                    job.idt_knowledge_processing_job ===
                    data.knowledge_processing_job_id
                  ) {
                    return {
                      ...job,
                      progress: data.process,
                    };
                  }
                  return job;
                }
              ),
            };
          }
          return item;
        });
        setSliderData(updatedData);
      } else if (data.title !== undefined) {
        loadDataNoLoading();
      }
    }
  }, [ws, dataSocket]);

  useEffect(() => {
    if (sliderData.length < 1) {
      loadData();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setSlidesConfig(getSlidesConfig(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (sliderRef.current) {
      const track = sliderRef.current.querySelector(
        ".know-process .slick-track"
      );
      if (track) {
        if (sliderData.length < slidesConfig) {
          track.classList.add("slick-lock");
        } else {
          track.classList.remove("slick-lock");
        }
      }
    }
  }, [sliderData, slidesConfig]);

  const settings = {
    // dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: slidesConfig,
    slidesToScroll: slidesConfig,
    initialSlide: currentSlide,
    afterChange: (current) => {
      setCurrentSlide(current);
      if (current === sliderData.length - 1 && sliderData.length % 10 === 0) {
        setCurrentPage((prevPage) => prevPage + 1);
        addMoreData();
      }
    },
    nextArrow: <NextArrow slidesToShow={slidesConfig} />,
    prevArrow: <PrevArrow slidesToShow={slidesConfig} />,
  };

  const removeData = (dataId) => {
    setSliderData((prevItems) => {
      const updatedItems = prevItems.filter(
        (item) => item.idt_knowledge !== dataId
      );
      return updatedItems;
    });
  };

  const handleReProcess = (data) => {
    axios
      .post(
        API_URL + "re-process-knowledge",
        {
          idt_knowledge_processing_job:
            data.knowledge_processing_job[0].idt_knowledge_processing_job,
          idt_knowledge: data.idt_knowledge,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((resp) => {
        if (resp.data.success === true) {
          showSuccess(resp.data.message);
          loadData();
        } else {
          showError(resp.data.message);
        }
      })
      .catch((error) => {
        showError(error);
      });
  };

  const handleDeleteClick = (id, type, message) => {
    showSuccess(message);
    loadData();
  };

  return sliderData.length > 0 ? (
    <div className="container-fluid w-85">
      <div className="row">
        <div className="d-flex active collapsed">
          <div className="file-list-link fs-14">Knowledge Processing</div>
          <div className="line-with-text2"></div>
        </div>
        <div className="w-100 know-process" ref={sliderRef}>
          <Slider {...settings}>
            {loading
              ? sliderData.map((index) => <CardSkeleton key={index} />)
              : sliderData.map((card, index) => (
                  <div key={index} className="p-25">
                    <div className="card card-list bg-light shadow-lg">
                      <div className="card-body">
                        <div className="text-start">
                          <div className="mb-2">
                            <div className="square-container">
                              <ProgressBar
                                // dataId={card.idt_knowledge}
                                progress={
                                  card.knowledge_processing_job.length > 0
                                    ? card.knowledge_processing_job[0].progress
                                    : 0
                                }
                                // removeData={removeData}
                              />
                            </div>
                          </div>
                          <div style={{ height: "36px" }}>
                            <h6 className="fs-12 fw-bold ellipsis-2-line knowledge-name">
                              {card.title}
                            </h6>
                          </div>
                        </div>
                        <p className="text-start fs-11 knowledge-name mb-0">
                          {card.type !== undefined ? (
                            card.type === "private" ? (
                              <>
                                <LuBookLock
                                  size={11}
                                  className="me-1"
                                  style={{ marginTop: "-3px" }}
                                />
                                Personal Private
                              </>
                            ) : (
                              <>
                                <LuBookUp
                                  size={11}
                                  className="me-1"
                                  style={{ marginTop: "-3px" }}
                                />
                                Personal Publish
                              </>
                            )
                          ) : (
                            <>
                              <LuBookCopy
                                size={11}
                                className="me-1"
                                style={{ marginTop: "-3px" }}
                              />
                              Collection
                            </>
                          )}
                        </p>
                        <p className="text-start fs-12 knowledge-name mb-0">
                          {card.author}
                        </p>
                        <p className="text-start fs-12 knowledge-name mb-0">
                          {card.knowledge_publisher
                            ? card.knowledge_publisher.name
                            : null}
                        </p>
                      </div>
                      {card.knowledge_processing_job.length > 0
                        ? card.knowledge_processing_job[0].status >= 90 && (
                            <div className="panel-button d-flex">
                              <Dropdown
                                className="card-btn-dropdown"
                                style={{
                                  position: "absolute",
                                  top: "8%",
                                  right: "14%",
                                }}
                              >
                                <Dropdown.Toggle
                                  className="btn btn-ghost-primary-2 btn-icon btn-xs dropdown rounded-4"
                                  style={{
                                    height: "calc(0.4rem + 0.8em + 5px)",
                                    width: "calc(0.4rem + 0.8em + 5px)",
                                  }}
                                >
                                  <i className="ri-more-2-fill fs-14 align-bottom"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end">
                                  <Dropdown.Item
                                    onClick={() => handleReProcess(card)}
                                  >
                                    <HiOutlineDocumentArrowUp
                                      size={15}
                                      className="me-2"
                                    />
                                    Re-Process
                                  </Dropdown.Item>
                                  <ModalRemove
                                    onDelete={handleDeleteClick}
                                    item={card}
                                  />
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          )
                        : null}
                    </div>
                  </div>
                ))}
          </Slider>
        </div>
      </div>
    </div>
  ) : loading ? null : null;
};

export default CarouselProcessing;
