import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../provider/languageContext";
import storage from "../../services/storage";
import axios from "axios";
import { API_URL } from "../../utilities/url";
import { format_number } from "../../utilities/ext-function";
import bookComp from "../../assets/images/book-comp.png";
import image_knowledge from "../../assets/images/knowledge.png";
import Skeleton from "react-loading-skeleton";
import { ModalAddReview, ModalDetailReview } from "../../components";
import { ToastContainer } from "react-toastify";
import ReactStars from "react-rating-stars-component";

const OrderDetail = () => {
  const { messagesLang } = useLanguage();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [data, setData] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchResult, setSearchResult] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.value === "") {
      setActiveSearch(false);
    }
    if (!activeSearch) {
      setSearchResult(e.target.value);
    }
  };
  const clearInput = () => {
    setActiveSearch(false);
    setInputValue("");
  };
  const handleChangeData = (newReview) => {
    setData((prevData) => ({
      ...prevData,
      order_detail: prevData.order_detail.map((detail) =>
        detail.knowledge.idt_knowledge === newReview.idt_knowledge
          ? {
              ...detail,
              knowledge: {
                ...detail.knowledge,
                knowledge_review: [
                  ...detail.knowledge.knowledge_review,
                  newReview,
                ],
              },
            }
          : detail
      ),
    }));
  };

  useEffect(() => {
    if (storage.get("orderId") !== orderId) {
      navigate("/order-list");
    } else {
      axios
        .post(
          API_URL + "get-knowledge-order",
          { id_order: orderId, status: "all" },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setData(response.data.data);
          } else {
            navigate("/order-list");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    storage.remove("orderId");
  }, []);

  const calculateTotalCombined = (data) => {
    let totalCombined = 0;

    data.forEach((item) => {
      totalCombined += item.price;
    });

    return totalCombined;
  };

  const getPrice = (card) => {
    if (card.idt_knowledge !== undefined) {
      if (card.knowledge_public.length > 0) {
        if (card.knowledge_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (card.knowledge_public[0].pricing_scheme === "rent") {
          return "Rp. " + format_number(card.knowledge_public[0].price_rent);
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    } else {
      if (card.knowledge_collection_public.length > 0) {
        if (card.knowledge_collection_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (
          card.knowledge_collection_public[0].pricing_scheme === "rent"
        ) {
          return (
            "Rp. " +
            format_number(card.knowledge_collection_public[0].price_rent)
          );
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    }
  };

  return (
    <div className="page-content">
      <ToastContainer toastClassName="toaster" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-8">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h5 className="card-title flex-grow-1 mb-0">{orderId}</h5>
                  {/* <div className="flex-shrink-0">
                    <a
                      href="apps-invoices-details.html"
                      className="btn btn-success btn-sm"
                    >
                      <i className="ri-download-2-fill align-middle me-1"></i>{" "}
                      Invoice
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="card-body">
                <div
                  className="table-responsive table-card"
                  style={{ overflow: "hidden" }}
                >
                  <table className="table table-nowrap align-middle table-borderless mb-0">
                    <thead className="table-light text-muted">
                      <tr>
                        <th scope="col">Product Details</th>
                        <th scope="col">Rating</th>
                        <th scope="col" className="text-end">
                          Item Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data ? (
                        data.order_detail.map((item, index) => (
                          <tr>
                            <td>
                              <div
                                className="d-flex"
                                style={{ minHeight: "133px" }}
                              >
                                <div className="flex-shrink-0 avatar-lg bg-light rounded p-0">
                                  <img
                                    src={
                                      item.knowledge
                                        ? item.knowledge.knowledge_cover_pic
                                            .length > 0
                                          ? item.knowledge
                                              .knowledge_cover_pic[0].pathurl
                                          : image_knowledge
                                        : item.knowledge_collection.url_coverpic
                                        ? item.knowledge_collection.url_coverpic
                                        : bookComp
                                    }
                                    alt=""
                                    className="img-fluid d-block image-product rounded"
                                  />
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <h5 className="fs-15">
                                    {item.knowledge
                                      ? item.knowledge.title
                                      : item.knowledge_collection.name}
                                  </h5>
                                  {item.knowledge ? (
                                    <>
                                      <p className="text-muted mb-0">
                                        Author:{" "}
                                        <span className="fw-medium">
                                          {item.knowledge.author
                                            ? item.knowledge.author
                                            : "-"}
                                        </span>
                                      </p>
                                      <p className="text-muted mb-0">
                                        Publisher:{" "}
                                        <span className="fw-medium">
                                          {item.knowledge.knowledge_publisher
                                            ? item.knowledge.knowledge_publisher
                                                .name
                                            : "-"}
                                        </span>
                                      </p>
                                      <p className="text-muted mb-0">
                                        Category:{" "}
                                        <span className="fw-medium">
                                          {item.knowledge.knowledge_category_map
                                            .length > 0
                                            ? item.knowledge.knowledge_category_map.map(
                                                (data, index) => {
                                                  return index === 0
                                                    ? data.category.name
                                                    : `, ${data.category.name}`;
                                                }
                                              )
                                            : "-"}
                                        </span>
                                      </p>
                                    </>
                                  ) : (
                                    <ul className="list-inline text-muted mb-0">
                                      <li className="list-inline-item">
                                        Collection
                                      </li>
                                    </ul>
                                  )}
                                  <p className="text-muted mb-0">
                                    Uploaded By:{" "}
                                    <span className="fw-medium">
                                      {item.knowledge
                                        ? `(${item.knowledge.user.user_profile.name_first} 
                                      ${item.knowledge.user.user_profile.name_last})`
                                        : `(${item.knowledge_collection.user.user_profile.name_first} 
                                      ${item.knowledge_collection.user.user_profile.name_last})`}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              {item.knowledge ? (
                                item.knowledge.knowledge_review.length > 0 ? (
                                  <>
                                    <ReactStars
                                      count={5}
                                      value={
                                        item.knowledge.knowledge_review[0]
                                          .rating
                                      }
                                      size={20}
                                      emptyIcon={
                                        <i className="ri-star-fill"></i>
                                      }
                                      fullIcon={
                                        <i className="ri-star-fill"></i>
                                      }
                                      activeColor="#ffd700"
                                      edit={false} // Membuat bintang tidak dapat diubah
                                    />
                                    <div>
                                      <ModalDetailReview
                                        data={item.knowledge}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <ModalAddReview
                                    data={item.knowledge}
                                    onChangeData={handleChangeData}
                                  />
                                )
                              ) : item.knowledge_collection
                                  .knowledge_collection_review.length > 0 ? (
                                <>
                                  <ReactStars
                                    count={5}
                                    value={
                                      item.knowledge_collection
                                        .knowledge_collection_review[0].rating
                                    }
                                    size={20}
                                    emptyIcon={<i className="ri-star-fill"></i>}
                                    fullIcon={<i className="ri-star-fill"></i>}
                                    activeColor="#ffd700"
                                    edit={false} // Membuat bintang tidak dapat diubah
                                  />
                                  <div>
                                    <ModalDetailReview
                                      data={item.knowledge_collection}
                                    />
                                  </div>
                                </>
                              ) : (
                                <ModalAddReview
                                  data={item.knowledge_collection}
                                  onChangeData={handleChangeData}
                                />
                              )}
                            </td>
                            <td className="text-end">
                              {getPrice(
                                item.knowledge
                                  ? item.knowledge
                                  : item.knowledge_collection
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>
                            <div
                              className="d-flex"
                              style={{ minHeight: "133px" }}
                            >
                              <div className="flex-shrink-0 avatar-lg bg-light rounded p-0">
                                <Skeleton
                                  className="custom-skeleton"
                                  height={133}
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h5 className="fs-15">
                                  <Skeleton
                                    className="custom-skeleton"
                                    height={20}
                                    width={300}
                                  />
                                </h5>
                                <p className="text-muted mb-0">
                                  <Skeleton
                                    className="custom-skeleton"
                                    height={15}
                                  />
                                </p>
                                <p className="text-muted mb-0">
                                  <Skeleton
                                    className="custom-skeleton"
                                    height={15}
                                  />
                                </p>
                                <p className="text-muted mb-0">
                                  <Skeleton
                                    className="custom-skeleton"
                                    height={15}
                                  />
                                </p>
                                <p className="text-muted mb-0">
                                  <Skeleton
                                    className="custom-skeleton"
                                    height={15}
                                  />
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <Skeleton
                              className="custom-skeleton"
                              height={20}
                              width={100}
                            />
                          </td>
                          <td className="text-end">
                            <Skeleton
                              className="custom-skeleton"
                              height={20}
                              width={100}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body checkout-tab">
                {data && data.total_payment > 0 && (
                  <div>
                    <h5 className="mb-1">{messagesLang.payment_method}</h5>
                    <p className="text-muted mb-4">
                      Please select and enter your payment method.
                    </p>
                  </div>
                )}
                <div className="row g-12">
                  <div className="col-lg-12 col-sm-12">
                    {data && data.total_payment > 0 && (
                      <div className="card mb-4">
                        <div className="row mb-2">
                          <div className="col-md-6">
                            <label for="cc-name">Bank Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="cc-number"
                              value="Bank BNI"
                              readOnly="readonly"
                            />
                          </div>
                          <div className="col-md-6">
                            <label for="cc-name">No. VA</label>
                            <input
                              type="text"
                              className="form-control"
                              id="cc-number"
                              placeholder="xxxx xxxx xxxx xxxx"
                              readOnly="readonly"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="d-flex justify-content-between gap-3">
                      <Link
                        to="/order-list"
                        className="btn btn-primary btn-label previestab"
                      >
                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                        {messagesLang.back_to} {messagesLang.order_list}
                      </Link>
                      <div>
                        {data && data.status === 0 && (
                          <button
                            onClick={() => alert("Cancel Order")}
                            className="btn btn-danger btn-label previestab"
                          >
                            <i className="mdi mdi-archive-remove-outline label-icon align-middle fs-16 me-2"></i>
                            {messagesLang.cancel_order}
                          </button>
                        )}
                      </div>
                      {/* <button
                        type="button"
                        className="btn btn-orange btn-label right ms-auto nexttab"
                        data-nexttab="pills-finish-tab"
                      >
                        <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>
                        Complete Order
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="sticky-side-div">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i>{" "}
                    {messagesLang.payment_detail}
                  </h5>
                </div>
                <div className="card-body pt-2">
                  <div className="table-responsive">
                    <table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td className="p-2">Transactions :</td>
                          <td className="p-2 text-end" id="cart-subtotal">
                            #VLZ124561278124
                          </td>
                        </tr>
                        <tr>
                          <td className="p-2">
                            {messagesLang.payment_method} :
                          </td>

                          {data && data.total_payment > 0 ? (
                            <td className="p-2 text-end" id="cart-subtotal">
                              Debit Card / Bank
                            </td>
                          ) : (
                            <td className="p-2 text-end" id="cart-subtotal">
                              -
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td className="p-2">Name / Bank Name :</td>
                          {data && data.total_payment > 0 ? (
                            <td className="p-2 text-end" id="cart-subtotal">
                              -
                            </td>
                          ) : (
                            <td className="p-2 text-end" id="cart-subtotal">
                              -
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td className="p-2">Card Number / No. VA :</td>
                          <td className="p-2 text-end" id="cart-subtotal">
                            6372618764124
                          </td>
                        </tr>
                        <tr>
                          <td className="p-2">Status Order :</td>
                          <td className="p-2 text-end" id="cart-subtotal">
                            {data && data.status === 0 && (
                              <span className="badge rounded-pill bg-primary">
                                In Progress
                              </span>
                            )}
                            {data && data.status === 1 && (
                              <span className="badge rounded-pill bg-success">
                                Finish
                              </span>
                            )}
                            {data && data.status === -1 && (
                              <span className="badge rounded-pill bg-danger">
                                Canceled
                              </span>
                            )}
                            {!data && "-"}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-2">Sub Total :</td>
                          <td className="p-2 text-end" id="cart-subtotal">
                            {data
                              ? calculateTotalCombined(data.order_detail)
                              : 0}
                          </td>
                        </tr>
                        {data && data.discount_name && (
                          <tr>
                            <td className="p-2">
                              {messagesLang.discount}{" "}
                              <span className="text-muted">
                                ({data.discount_name})
                              </span>{" "}
                              :{" "}
                            </td>
                            <td className="p-2 text-end" id="cart-discount">
                              - {format_number(data.discount_value)}
                            </td>
                          </tr>
                        )}
                        {data && data.tax > 0 && (
                          <tr>
                            <td className="p-2">{messagesLang.tax} (5%) : </td>
                            <td className="p-2 text-end" id="cart-tax">
                              {format_number(data.tax)}
                            </td>
                          </tr>
                        )}
                        <tr className="table-active">
                          <th className="p-2">Total (IDR) :</th>
                          <td className="p-2 text-end">
                            <span className="fw-semibold" id="cart-total">
                              {data ? format_number(data.total_payment) : 0}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
