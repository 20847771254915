import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { useLanguage } from "../../provider/languageContext";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../utilities/url";
import storage from "../../services/storage";
import moment from "moment";
import { NotificationContext } from "../../provider/notificationContext";
import { WebSocketContext } from "../../provider/webSocketContext";

const HeaderNotification = () => {
  const { messagesLang } = useLanguage();
  const { ws, dataSocket } = useContext(WebSocketContext);
  const { unreadCount, setUnreadCount } = useContext(NotificationContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    if (ws && dataSocket) {
      const data = dataSocket;
      if (data.notification_type) {
        setUnreadCount((prevCount) => prevCount + 1);
      }
    }
  }, [ws, dataSocket]);

  const handleToggle = (isOpen) => {
    setShow(isOpen);
    if (isOpen) {
      setLoading(true);
      axios
        .post(
          API_URL + "notification",
          { status: "all" },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.success === true) {
            setNotification(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setUnreadCount(0);
    }
  };
  const handleViewAllClick = () => {
    setShow(false);
    setUnreadCount(0);
  };

  return (
    <div>
      <Dropdown
        className="topbar-head-dropdown ms-1 header-item"
        show={show}
        onToggle={handleToggle}
      >
        <Dropdown.Toggle
          variant=""
          className="dropdown d-flex btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-bell fs-22"></i>
          <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
            {unreadCount > 0 && (
              <>
                {unreadCount}
                <span className="visually-hidden">unread messages</span>
              </>
            )}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-danger rounded-top">
            <div className="p-3">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="m-0 fs-14 fw-semibold text-white">
                    {messagesLang.notifications}
                  </h6>
                </div>
                <div className="col-auto dropdown-tabs">
                  {unreadCount > 0 && (
                    <span className="badge bg-light-subtle text-body fs-11">
                      {unreadCount} {messagesLang.new}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="px-2 pt-2">
              <ul
                className="nav nav-tabs dropdown-tabs nav-tabs-custom-notif"
                data-dropdown-tabs="true"
                id="notificationItemsTab"
                role="tablist"
              >
                <li className="nav-item waves-effect waves-light">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#all-noti-tab"
                    role="tab"
                    aria-selected="true"
                  >
                    {messagesLang.messages}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="tab-content position-relative"
            id="notificationItemsTabContent"
          >
            <div
              className="tab-pane fade show active py-2 ps-2"
              id="all-noti-tab"
              role="tabpanel"
            >
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                {/* Notification items */}
                {loading ? (
                  <div className="text-center p-2">
                    <Spinner
                      className="flex-shrink-0"
                      animation="border"
                      size="sm"
                    />
                  </div>
                ) : notification.length > 0 ? (
                  notification.map((data, index) => (
                    <div
                      key={index}
                      className="text-reset notification-item d-block dropdown-item position-relative"
                    >
                      <div className="d-flex">
                        <div className="avatar-xs me-3 flex-shrink-0">
                          {data.title === "Knowledge Training Success" && (
                            <span className="avatar-title bg-success-subtle text-success rounded-circle fs-16">
                              <i className="bx bx-badge-check"></i>
                            </span>
                          )}
                          {data.title === "Knowledge Training Error" && (
                            <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-16">
                              <i className="bx bx-badge-check"></i>
                            </span>
                          )}
                          {data.title !== "Knowledge Training Error" &&
                            data.title !== "Knowledge Training Success" && (
                              <span className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                <i className="bx bx-badge-check"></i>
                              </span>
                            )}
                        </div>
                        <div className="flex-grow-1">
                          <a href="#" className="stretched-link">
                            <h6 className="mt-0 mb-0 lh-base">
                              {data.description}
                            </h6>
                            <div class="fs-13 text-muted">
                              <p class="mb-1">{data.title}</p>
                            </div>
                          </a>
                          <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                            <span>
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              {moment(data.ts_created).fromNow()}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-reset notification-item d-block dropdown-item position-relative">
                    <div className="p-2 text-center">No data Notification</div>
                  </div>
                )}
                {/* Additional items */}
              </SimpleBar>
            </div>
          </div>

          {/* Footer */}
          <div className="dropdown-footer bg-danger text-center p-2">
            <Link
              to="/notifications"
              className="btn btn-link text-white fs-13"
              onClick={handleViewAllClick}
            >
              View All Notifications
            </Link>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      {/* removeNotificationModal */}
      <div
        id="removeNotificationModal"
        className="modal fade zoomIn"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <Button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="NotificationModalbtn-close"
              ></Button>
            </div>
            <div className="modal-body">
              <div className="mt-2 text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/gsqxdxog.json"
                  trigger="loop"
                  colors="primary:#f7b84b,secondary:#f06548"
                  style={{ width: 100, height: 100 }}
                ></lord-icon>
                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                  <h4>Are you sure ?</h4>
                  <p className="text-muted mx-4 mb-0">
                    Are you sure you want to remove this Notification ?
                  </p>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                <Button
                  type="button"
                  className="btn w-sm btn-light"
                  data-bs-dismiss="modal"
                >
                  Close
                </Button>
                <Button
                  type="button"
                  className="btn w-sm btn-danger"
                  id="delete-notification"
                >
                  Yes, Delete It!
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderNotification;
