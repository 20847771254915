import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logoImg from "../assets/images/logo-dark.png";
import { API_URL } from "../utilities/url";
import authHeader from "../services/auth.header";
import storage from "../services/storage";
import CryptoJS from "crypto-js";
import { useLanguage } from "../provider/languageContext";
import HeaderLanguage from "./component/headerLanguage";
import { Spinner } from "react-bootstrap";

const RedirectLogin = () => {
  const navigate = useNavigate();
  const { messagesLang } = useLanguage();

  useEffect(() => {
    // Proses pengecekan atau pengambilan data
    const urlParams = new URLSearchParams(window.location.search);
    const dataParam = urlParams.get("data");
    if (dataParam) {
      axios
        .post(
          API_URL + "validate-from-chat",
          {},
          {
            headers: {
              Authorization: `Bearer ${dataParam}`, // Perbaikan: Authorization harus berupa objek
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            storage.set(
              "user",
              CryptoJS.AES.encrypt(
                JSON.stringify(response.data.data),
                "user"
              ).toString()
            );
            storage.set("access_token", response.data.data.token);
            window.location.replace("home");
          } else {
            Logout();
          }
        })
        .catch((error) => {
          Logout();
        });
    } else {
      navigate("/login");
    }
  }, []);

  const Logout = () => {
    axios
      .post(
        API_URL + "logout",
        {},
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        localStorage.clear();
        navigate("/login");
      })
      .catch((error) => {
        navigate("/login");
      });
  };

  return (
    <div className="auth-page-content pb-0">
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between mt-sm-4 mb-sm-2 text-white-50">
            <img
              src={logoImg}
              alt=""
              height="40"
              className="d-none d-sm-block"
            />
            <div className="head-lang">
              <HeaderLanguage />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="centered-container">
              <div className="card login rounded-4 mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-white">
                      {messagesLang.redirect_to} Otobaca
                    </h5>
                    <Spinner
                      className="m-5 text-orange"
                      style={{ width: "3rem", height: "3rem" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedirectLogin;
