import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { ThemeContext } from "../provider/themeContext";
import { ToastrContext } from "../provider/toastrContext";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
// Import the plugin styles
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import CustomStyles from "./customStyles";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import { API_URL, API_URL_CORE } from "../utilities/url";
import storage from "../services/storage";
import upload from "../assets/images/upload.png";
import { RiCameraFill } from "react-icons/ri";
import { FaUserAstronaut } from "react-icons/fa";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageCrop,
  FilePondPluginImageEdit,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

const ModalUpdateKnowledge = ({ onUpdate, handleClose, show, item }) => {
  const { theme } = useContext(ThemeContext);
  const { showSuccess, showError } = useContext(ToastrContext);
  const [dataItem, setDataItem] = useState(item);
  const [filesImg, setFilesImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [imagePreview, setImagePreview] = useState(
    item.knowledge_cover_pic.length > 0
      ? item.knowledge_cover_pic[0].pathurl
      : upload
  );
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    setSubCategories([]);
    setValue("subcategory", []);
    if (selectedOption) {
      axios
        .get(
          `${API_URL}get-knowledge-subcategory?category=${selectedOption.value}`,
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            const maps = response.data.data.map(function (d) {
              const map = {
                value: d.idt_knowledge_category,
                label: d.name,
              };
              return map;
            });
            setSubCategories(maps);
          }
        })
        .catch((error) => {});
    } else {
      setSubCategories([]);
    }
  };

  const [publisherList, setPublisherList] = useState([]);

  const handleInputChange = (event) => {
    if (event.target.name === "description") {
      adjustTextareaHeight();
    }
    clearErrors(event.target.name);
  };

  const adjustTextareaHeight = () => {
    const elements = document.getElementsByName("description");
    const element = elements[1];
    element.style.height = "auto";
    element.style.height = `${element.scrollHeight}px`;
  };

  useEffect(() => {
    if (show) {
      //   console.log(dataItem);
      adjustTextareaHeight();
      setValue("title", dataItem.title);
      setValue("author", dataItem.author);
      setValue("description", dataItem.description);
      setValue("isbn", dataItem.isbn);
      setValue("idt_knowledge", dataItem.idt_knowledge);

      // Get Publisher
      axios
        .get(API_URL + "get-publisher", {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            const maps = response.data.data.map(function (d) {
              const map = {
                value: d.idt_publisher,
                label: d.name,
              };
              return map;
            });
            setPublisherList(maps);
            const defaultPublisher = maps.find(
              (p) => p.value === dataItem.knowledge_publisher.idt_publisher
            );
            setValue("publisher", defaultPublisher);
          }
        })
        .catch((error) => {
          setLoading(false);
        });

      // Get Category
      axios
        .get(API_URL + "get-knowledge-category", {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            const maps = response.data.data.map(function (d) {
              const map = {
                value: d.idt_knowledge_category,
                label: d.name,
              };
              return map;
            });
            setCategoryList(maps);
            const defaultCategory = maps.find(
              (p) =>
                p.value ===
                dataItem.knowledge_category_map[0].category.id_parent
            );
            setSelectedCategory(defaultCategory);
            setValue("category", defaultCategory);
          }
        })
        .catch((error) => {
          setLoading(false);
        });

      // Get SubCategory
      if (dataItem.knowledge_category_map.length > 0)
        axios
          .get(
            `${API_URL}get-knowledge-subcategory?category=${dataItem.knowledge_category_map[0].category.id_parent}`,
            {
              headers: {
                Authorization: `Bearer ${storage.get("access_token")}`,
              },
            }
          )
          .then((response) => {
            if (response.data.success === true) {
              const maps = response.data.data.map(function (d) {
                const map = {
                  value: d.idt_knowledge_category,
                  label: d.name,
                };
                return map;
              });
              setSubCategories(maps);
              const defaultSubCategories = dataItem.knowledge_category_map.map(
                (data) =>
                  maps.find(
                    (p) => p.value === data.category.idt_knowledge_category
                  )
              );
              setValue("subcategory", defaultSubCategories);
            }
          })
          .catch((error) => {});
    }
  }, [show]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setFilesImg(file);
      setValue("images", file);
    } else {
      setImagePreview(
        item.knowledge_cover_pic.length > 0
          ? item.knowledge_cover_pic[0].pathurl
          : upload
      );
      setFilesImg(null);
      setValue("images", null);
    }
  };

  const handleModalClose = () => {
    reset();
    handleClose();
    setImagePreview(
      item.knowledge_cover_pic.length > 0
        ? item.knowledge_cover_pic[0].pathurl
        : upload
    );
    setFilesImg(null);
    setValue("images", null);
  };

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(API_URL + "update-knowledge", data, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          handleClose("knowledge");
          showSuccess(response.data.message);
          onUpdate(response.data.data, "knowledge");
          setLoading(false);
        } else {
          showError(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        showError("An error occurred. Please try again later.");
        setLoading(false);
      });
  };

  const handleGenImg = async (data) => {
    try {
      setLoadingImg(true);
      const response = await axios.post(API_URL_CORE + "image/cover", {
        knowledge_description: data.description,
        style: "abstract",
      });

      if (response.data.errormsg === "OK") {
        const base64String = response.data.data.images[0];
        setImagePreview(`data:image/png;base64,${base64String}`);
        const base64Data = base64String.split(",")[0];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], { type: "image/png" });
        const file = new File([blob], "profile_pic.png", { type: "image/png" });
        setFilesImg(file);
        setValue("images", file);
      }
      setLoadingImg(false);
    } catch (error) {
      console.error("Error generating image:", error);
      setImagePreview(null);
      setLoadingImg(false);
    }
  };

  return (
    <>
      {/* <button onClick={handleShow} className="btn btn-upload-wd mb-4">
        <div>
          <FiUpload className="pt-2" size={36} />
          <p className="pt-2 fs-16">Add Knowledge</p>
        </div>
      </button> */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="custom-modal-collection fade"
        onHide={handleModalClose}
      >
        <Modal.Header className="p-3 bg-light-dark" closeButton>
          <h5 className="modal-title">Update Knowledge</h5>
        </Modal.Header>
        <SimpleBar className="modal-body knowledge">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="profile-user position-relative d-flex justify-content-center mx-auto mb-2">
                      <img
                        src={imagePreview ? imagePreview : upload}
                        className="avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile-image"
                        style={{
                          height: "250px",
                          width: "187.5px",
                          borderRadius: "15px",
                        }}
                      />
                      {/* <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <input
                          id="profile-img-file-input"
                          name="images"
                          type="file"
                          className="profile-img-file-input"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                        <label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit-2 avatar-xs"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="ri-camera-fill"></i>
                          </span>
                        </label>
                      </div> */}
                    </div>
                    <div className="profile-user position-relative d-flex flex-column align-items-center mx-auto mb-4">
                      <div className="p-0 mb-2">
                        <input
                          id="profile-img-file-input"
                          name="images"
                          type="file"
                          className="profile-img-file-input"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                        <label
                          htmlFor="profile-img-file-input"
                          className="btn btn-primary m-auto w-lg"
                          style={{ width: "180px" }}
                        >
                          <span className="text-white">
                            <RiCameraFill className="me-2" size={14} />
                            Upload Image
                          </span>
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn btn-info w-lg"
                        onClick={handleSubmit(handleGenImg)}
                        disabled={loadingImg}
                        style={{ width: "180px" }}
                      >
                        {loadingImg ? (
                          <>
                            <Spinner
                              className="flex-shrink-0"
                              animation="border"
                              size="sm"
                            />
                          </>
                        ) : (
                          <>
                            <FaUserAstronaut className="me-2" size={14} />
                            Generate AI Image
                          </>
                        )}
                      </button>
                    </div>
                    <label className="form-label w-100">
                      Re-Train Knowledge
                    </label>
                    <Controller
                      name="files"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <FilePond
                          {...field}
                          files={field.value}
                          instantUpload={true}
                          // chunkUploads={true}
                          allowFileTypeValidation={true}
                          acceptedFileTypes={[
                            "application/pdf",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          ]}
                          labelIdle="Drag & Drop or Browse"
                          onupdatefiles={(fileItems) => {
                            const Files = fileItems.map(
                              (fileItem) => fileItem.file
                            );
                            field.onChange(Files);
                          }}
                          onblur={field.onBlur}
                        />
                      )}
                    />
                    {errors.files && (
                      <p
                        className="errorMsg text-danger fs-7"
                        style={{ marginTop: "-15px" }}
                      >
                        {errors.files.message}
                      </p>
                    )}
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className="form-label w-100">
                        Title <span style={{ color: "#f06548" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        {...register("title", {
                          required: true,
                        })}
                        maxLength={80}
                      />
                      {errors.title && errors.title.type === "required" && (
                        <p className="errorMsg text-danger fs-7">
                          Name is required.
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="form-label w-100">Author</label>
                      <input
                        type="text"
                        className="form-control"
                        name="author"
                        {...register("author", {
                          required: false,
                        })}
                      />
                      {errors.author && errors.author.type === "required" && (
                        <p className="errorMsg text-danger fs-7">
                          Author is required.
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="form-label w-100">Publisher</label>
                      <Controller
                        name="publisher"
                        control={control}
                        // rules={{
                        //   required: "Publisher is required.",
                        // }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            styles={CustomStyles(theme)}
                            className="custom-select"
                            maxMenuHeight={200}
                            options={publisherList}
                            placeholder="Select Publisher"
                            onBlur={field.onBlur}
                            onChange={(selectedPublisher) => {
                              field.onChange(selectedPublisher);
                              clearErrors("publisher");
                            }}
                            isSearchable={true}
                          />
                        )}
                      />
                      {errors.publisher && (
                        <p className="errorMsg text-danger fs-7">
                          {errors.publisher.message}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="form-label w-100">ISBN</label>
                      <input
                        type="text"
                        className="form-control"
                        name="isbn"
                        {...register("isbn", {
                          required: false,
                        })}
                        maxLength={30}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="form-label w-100">Category</label>
                      <Controller
                        name="category"
                        control={control}
                        // rules={{
                        //   required: "Category is required.",
                        // }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            styles={CustomStyles(theme)}
                            value={selectedCategory}
                            className="custom-select"
                            maxMenuHeight={200}
                            options={categoryList}
                            placeholder="Select Category"
                            onBlur={field.onBlur}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              handleCategoryChange(selectedOption);
                              clearErrors("category");
                            }}
                            Searchable={true}
                          />
                        )}
                      />
                      {errors.category && (
                        <p className="errorMsg text-danger fs-7">
                          {errors.category.message}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="form-label w-100">Sub Category</label>
                      <Controller
                        name="subcategory"
                        control={control}
                        // rules={{
                        //   required: "SubCategory is required.",
                        // }}
                        rules={{
                          validate: (value) => {
                            if (
                              selectedCategory &&
                              (!value || value.length === 0)
                            ) {
                              return "SubCategory is required.";
                            }
                            return true;
                          },
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isMulti
                            styles={CustomStyles(theme)}
                            className="custom-select"
                            maxMenuHeight={200}
                            options={subCategories}
                            placeholder="Select SubCategory"
                            onBlur={field.onBlur}
                            onChange={(selected) => {
                              field.onChange(selected);
                              clearErrors("subcategory");
                            }}
                            isSearchable={true}
                            isDisabled={!selectedCategory}
                          />
                        )}
                      />
                      {errors.subcategory && (
                        <p className="errorMsg text-danger fs-7">
                          {errors.subcategory.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-4">
                  <label className="form-label w-100">
                    Description <span style={{ color: "#f06548" }}>*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="description"
                    {...register("description", {
                      required: true,
                    })}
                    onChange={handleInputChange}
                    style={{ minHeight: "100px", overflow: "hidden" }}
                  />
                  {errors.description &&
                    errors.description.type === "required" && (
                      <p className="errorMsg text-danger fs-7">
                        Description is required.
                      </p>
                    )}
                </div>
              </div>
              <div className="hstack gap-2 justify-content-end">
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <Button
                    type="submit"
                    className="btn btn-success w-sm"
                    disabled={loading ? "disabled" : ""}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          className="flex-shrink-0"
                          animation="border"
                          size="sm"
                        />
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </SimpleBar>
      </Modal>
    </>
  );
};

export default ModalUpdateKnowledge;
