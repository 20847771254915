import React, { createContext, useEffect, useState } from "react";
import storage from "../services/storage";
import { API_URL } from "../utilities/url";
import axios from "axios";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [unreadCount, setUnreadCount] = useState(0);
  useEffect(() => {
    axios
      .get(API_URL + "unread-notification", {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          setUnreadCount(response.data.data !== null ? response.data.data : 0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <NotificationContext.Provider value={{ unreadCount, setUnreadCount }}>
      {children}
    </NotificationContext.Provider>
  );
};
