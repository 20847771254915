import React, { useContext, useState } from "react";
import {
  Loading,
  ModalDetail,
  ModalDetailCollectionPublic,
  ModalSuccess,
} from "../../../components";
import { Spinner } from "react-bootstrap";
import { CartContext } from "../../../provider/cartContext";
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLanguage } from "../../../provider/languageContext";
import { API_URL } from "../../../utilities/url";
import axios from "axios";
import storage from "../../../services/storage";
import { LuBookCopy } from "react-icons/lu";
import bookComp from "../../../assets/images/book-comp.png";
import image_knowledge from "../../../assets/images/knowledge.png";
import { format_number } from "../../../utilities/ext-function";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { BiWorld } from "react-icons/bi";
import { ThemeContext } from "../../../provider/themeContext";

const TableList = ({ cards, loading }) => {
  const { messagesLang } = useLanguage();
  const { theme } = useContext(ThemeContext);
  const { addItemToCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showModalCollection, setShowModalCollection] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dataReview, setDataReview] = useState([]);
  const [totalDataReview, setTotalDataReview] = useState(0);
  const [starReview, setStarReview] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [pageReview, setPageReview] = useState(1);
  const [loadingReview, setLoadingReview] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingData2, setLoadingData2] = useState(false);

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setLoadingModal(true);
    if (item.idt_knowledge !== undefined) {
      /* Get AVG Review */
      axios
        .post(
          API_URL + "get-knowledge-avg-review",
          {
            idt_knowledge: item.idt_knowledge,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setStarReview(response.data.data);
          }
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
        });

      /* Get Review */
      axios
        .post(
          API_URL + "get-knowledge-review",
          {
            idt_knowledge: item.idt_knowledge,
            rating: selectedRatings,
            limit: 10,
            page: pageReview,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setLoadingModal(false);
            setShowModal(true);
            setDataReview(response.data.data.data);
            setPageReview((prevPage) => prevPage + 1);
            setTotalDataReview((prevTotal) => {
              const updatedTotal = prevTotal + response.data.data.data.length;
              setHasMore(updatedTotal < response.data.data.meta.total_items);
              return updatedTotal;
            });
          } else {
            setLoadingModal(false);
          }
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
        });
    } else {
      /* Get AVG Review */
      axios
        .post(
          API_URL + "get-knowledge-avg-review",
          {
            idt_knowledge_collection: item.idt_knowledge_collection,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setStarReview(response.data.data);
          }
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
        });

      /* Get Review */
      axios
        .post(
          API_URL + "get-knowledge-review",
          {
            idt_knowledge_collection: item.idt_knowledge_collection,
            rating: selectedRatings,
            limit: 10,
            page: pageReview,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setLoadingModal(false);
            setShowModalCollection(true);
            setDataReview(response.data.data.data);
            setPageReview((prevPage) => prevPage + 1);
            setTotalDataReview((prevTotal) => {
              const updatedTotal = prevTotal + response.data.data.data.length;
              setHasMore(updatedTotal < response.data.data.meta.total_items);
              return updatedTotal;
            });
          } else {
            setLoadingModal(false);
          }
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
        });
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setShowModalCollection(false);
    setSelectedItem(null);
    setLoadingData(false);
    setLoadingData2(false);
    setDataReview([]);
    setStarReview([]);
    setSelectedRatings([]);
    setTotalDataReview(0);
    setPageReview(1);
    setLoadingReview(false);
    setHasMore(true);
  };

  const handleCloseSuccess = () => {
    setShowModal(false);
    setShowSuccessModal(false);
    setSelectedItem(null);
    setLoadingData(false);
    setLoadingData2(false);
    setDataReview([]);
    setPageReview(1);
    setLoadingReview(false);
    setHasMore(true);
  };

  const handleKnowledge = (id, type) => {
    if (type === "get") {
      axios
        .post(
          API_URL + "get-knowledge-public-all",
          { id: id, page: 1, limit: 1 },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setShowModal(false);
            setLoadingData2(false);
            storage.setUnlimited(
              "cd",
              CryptoJS.AES.encrypt(
                JSON.stringify(response.data.data.data),
                "checkout"
              ).toString()
            );
            navigate("/checkout");
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              theme: "colored",
              transition: Slide,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
            setLoadingData2(false);
          }
        })
        .catch((error) => {
          setLoadingData2(false);
          console.log(error);
        });
    } else {
      axios
        .post(
          API_URL + "add-knowledge-cart",
          { idt_knowledge: id },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setShowModal(false);
            setShowSuccessModal(true);
            addItemToCart(response.data.data);
            setLoadingData(false);
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              theme: "colored",
              transition: Slide,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
            setLoadingData(false);
          }
        })
        .catch((error) => {
          setLoadingData(false);
          console.log(error);
        });
    }
  };

  const handleCollection = (id, type) => {
    if (type === "get") {
      axios
        .post(
          API_URL + "get-collection-public",
          { id: id, page: 1, limit: 1 },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setShowModalCollection(false);
            setLoadingData2(false);
            storage.setUnlimited(
              "cd",
              CryptoJS.AES.encrypt(
                JSON.stringify(response.data.data.data),
                "checkout"
              ).toString()
            );
            navigate("/checkout");
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              theme: "colored",
              transition: Slide,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
            setLoadingData2(false);
          }
        })
        .catch((error) => {
          setLoadingData2(false);
          console.log(error);
        });
    } else {
      axios
        .post(
          API_URL + "add-knowledge-cart",
          { idt_knowledge_collection: id },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setShowModalCollection(false);
            setShowSuccessModal(true);
            addItemToCart(response.data.data);
            setLoadingData(false);
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              theme: "colored",
              transition: Slide,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
            setLoadingData(false);
          }
        })
        .catch((error) => {
          setLoadingData(false);
          console.log(error);
        });
    }
  };

  const handleRatingChange = (rating, id, type) => {
    setSelectedRatings((prev) => {
      const updatedRatings = prev.includes(rating)
        ? prev.filter((r) => r !== rating)
        : [...prev, rating];

      // Panggil getReviewAPI dengan updatedRatings
      axios
        .post(
          API_URL + "get-knowledge-review",
          {
            idt_knowledge: type === "knowledge" ? id : null,
            idt_knowledge_collection: type === "collection" ? id : null,
            rating: updatedRatings,
            limit: 10,
            page: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setDataReview(response.data.data.data);
            setPageReview(2);
            setTotalDataReview(() => {
              const updatedTotal = response.data.data.data.length;
              setHasMore(updatedTotal < response.data.data.meta.total_items);
              return updatedTotal;
            });
          }
          setLoadingReview(false);
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
          setLoadingReview(false);
        });

      return updatedRatings;
    });
  };

  const loadMoreReview = (id, type) => {
    if (!hasMore) return;
    setLoadingReview(true);
    axios
      .post(
        API_URL + "get-knowledge-review",
        {
          idt_knowledge: type === "knowledge" ? id : null,
          idt_knowledge_collection: type === "collection" ? id : null,
          rating: selectedRatings,
          limit: 10,
          page: pageReview,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setDataReview((prevData) => [
            ...prevData,
            ...response.data.data.data,
          ]);
          setPageReview((prevPage) => prevPage + 1);
          setTotalDataReview((prevTotal) => {
            const updatedTotal = prevTotal + response.data.data.data.length;
            setHasMore(updatedTotal < response.data.data.meta.total_items);
            return updatedTotal;
          });
        }
        setLoadingReview(false);
      })
      .catch((error) => {
        console.log("An error occurred. Please try again later.");
        setLoadingReview(false);
      });
  };

  const getPrice = (card) => {
    if (card.idt_knowledge !== undefined) {
      if (card.knowledge_public.length > 0) {
        if (card.knowledge_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (card.knowledge_public[0].pricing_scheme === "rent") {
          return "Rp. " + format_number(card.knowledge_public[0].price_rent);
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    } else {
      if (card.knowledge_collection_public.length > 0) {
        if (card.knowledge_collection_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (
          card.knowledge_collection_public[0].pricing_scheme === "rent"
        ) {
          return (
            "Rp. " +
            format_number(card.knowledge_collection_public[0].price_rent)
          );
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    }
  };

  return (
    <div className="row mt-2 mb-4">
      <div className="col-md-12">
        <table
          className="table table-striped table-hover table-bordered"
          style={{
            borderColor: theme === "dark" ? "#000" : null,
          }}
        >
          <thead
            className="table-light"
            style={{
              borderColor: theme === "dark" ? "#000" : null,
            }}
          >
            <tr className="fs-15">
              <th scope="col fw-bold">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Author</th>
              <th scope="col">Publisher</th>
              <th scope="col">Price</th>
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              cards.length > 0 ? (
                <>
                  {cards.map((card, index) => (
                    <tr
                      key={index}
                      className="fs-14"
                      onClick={(event) => {
                        if (!event.target.closest(".card-btn-dropdown")) {
                          handleCardClick(card);
                        }
                      }}
                    >
                      <td className="align-middle">
                        <div className="d-flex align-items-center">
                          <img
                            src={
                              card.idt_knowledge !== undefined
                                ? card.knowledge_cover_pic.length > 0
                                  ? card.knowledge_cover_pic[0].pathurl
                                  : image_knowledge
                                : card.url_coverpic
                                ? card.url_coverpic
                                : bookComp
                            }
                            className="object-fit-cover rounded-3 me-3"
                            width={80}
                            height={106.67}
                            alt={index}
                          />
                          {card.title !== undefined ? card.title : card.name}
                        </div>
                      </td>
                      <td className="align-middle">
                        {card.type !== undefined ? (
                          <>
                            <BiWorld
                              size={11}
                              className="me-1"
                              style={{ marginTop: "-3px" }}
                            />
                            Public
                          </>
                        ) : (
                          <>
                            <LuBookCopy
                              size={11}
                              className="me-1"
                              style={{ marginTop: "-3px" }}
                            />
                            Collection
                          </>
                        )}
                      </td>
                      <td className="align-middle">
                        {card.author !== undefined
                          ? card.author
                            ? card.author
                            : "-"
                          : "Multiple Publisher"}
                      </td>
                      <td className="align-middle">
                        {card.knowledge_publisher !== undefined
                          ? card.knowledge_publisher
                            ? card.knowledge_publisher.name
                            : "-"
                          : "-"}
                      </td>
                      <td className="align-middle">{getPrice(card)}</td>
                    </tr>
                    // <div key={index} className="custom-coloum folder-card">
                    //   <div className="card card-list bg-light shadow-lg">
                    //     <div
                    //       onClick={() => handleCardClick(card)}
                    //       className="card-body"
                    //     >
                    //       <div className="text-start">
                    //         <div className="mb-2">
                    //           <div className="square-container">
                    //             <img
                    //               src={
                    //                 card.idt_knowledge !== undefined
                    //                   ? card.knowledge_cover_pic.length > 0
                    //                     ? card.knowledge_cover_pic[0].pathurl
                    //                     : image_knowledge
                    //                   : card.url_coverpic
                    //                   ? card.url_coverpic
                    //                   : bookComp
                    //               }
                    //               className="rounded-3"
                    //               alt={index}
                    //             />
                    //           </div>
                    //         </div>
                    //         <div style={{ height: "36px" }}>
                    //           <h6 className="fs-12 fw-bold ellipsis-2-line knowledge-name">
                    //             {card.title !== undefined
                    //               ? card.title
                    //               : card.name}
                    //           </h6>
                    //         </div>
                    //       </div>
                    //       <p className="text-start fs-11 knowledge-name mb-0">
                    //         {card.type !== undefined ? (
                    //           <>
                    //             <BiWorld
                    //               size={11}
                    //               className="me-1"
                    //               style={{ marginTop: "-3px" }}
                    //             />
                    //             Public
                    //           </>
                    //         ) : (
                    //           <>
                    //             <LuBookCopy
                    //               size={11}
                    //               className="me-1"
                    //               style={{ marginTop: "-3px" }}
                    //             />
                    //             Collection
                    //           </>
                    //         )}
                    //       </p>
                    //       <p className="text-start fw-bold fs-12 knowledge-name mb-0">
                    //         {getPrice(card)}
                    //       </p>
                    //       <p className="text-start fs-12 knowledge-name mb-0">
                    //         {card.author !== undefined
                    //           ? card.author
                    //           : "Multiple Publisher"}
                    //       </p>
                    //       <p className="text-start fs-12 knowledge-name mb-0">
                    //         {card.knowledge_publisher !== undefined
                    //           ? card.knowledge_publisher.name
                    //           : null}
                    //       </p>
                    //     </div>
                    //   </div>
                    // </div>
                  ))}
                  {loadingModal && <Loading />}
                  {selectedItem ? (
                    selectedItem.idt_knowledge === undefined ? (
                      <ModalDetailCollectionPublic
                        item={selectedItem}
                        show={showModalCollection}
                        handleClose={handleClose}
                        loading={loadingData}
                        setLoading={setLoadingData}
                        loading2={loadingData2}
                        setLoading2={setLoadingData2}
                        onHandleCollection={handleCollection}
                        review={dataReview}
                        starReview={starReview}
                        selectedRatings={selectedRatings}
                        onRatingChange={handleRatingChange}
                        loadMoreReview={loadMoreReview}
                        loadingReview={loadingReview}
                        hasMore={hasMore}
                      />
                    ) : (
                      <ModalDetail
                        show={showModal}
                        handleClose={handleClose}
                        item={selectedItem}
                        review={dataReview}
                        starReview={starReview}
                        selectedRatings={selectedRatings}
                        onRatingChange={handleRatingChange}
                        loadMoreReview={loadMoreReview}
                        loadingReview={loadingReview}
                        hasMore={hasMore}
                        loading={loadingData}
                        setLoading={setLoadingData}
                        loading2={loadingData2}
                        setLoading2={setLoadingData2}
                        onHandleKnowledge={handleKnowledge}
                      />
                    )
                  ) : null}
                  <ModalSuccess
                    show={showSuccessModal}
                    handleClose={handleCloseSuccess}
                  />
                </>
              ) : (
                <tr>
                  <td colspan="5">
                    <div
                      className="text-center fs-16"
                      style={{ marginTop: "60px", marginBottom: "120px" }}
                    >
                      {messagesLang.data_not_found}
                    </div>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colspan="5">
                  <div
                    class="w-100 d-flex justify-content-center align-items-center"
                    style={{ height: "315px" }}
                  >
                    <div class="loading-backdrop-2">
                      <div class="spinner-border"></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableList;
